import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCaretUp } from '@fortawesome/free-regular-svg-icons';  
import { faCircleUp } from '@fortawesome/free-solid-svg-icons';  


const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Fonction pour détecter le défilement de la page
  const toggleVisibility = () => {
    if (window.scrollY > 500) { // Le bouton devient visible après 300px de défilement
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Fonction pour faire défiler jusqu'en haut de la page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Défilement fluide
    });
  };

  useEffect(() => {
    // Ajout de l'écouteur d'événement pour surveiller le défilement
    window.addEventListener('scroll', toggleVisibility);

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div
          onClick={scrollToTop}
          className="scrollTopButton"
        >
          <FontAwesomeIcon icon={faCircleUp}/> 
        </div>
      )}
    </>
  );
};


export default ScrollToTopButton;