// src/components/DayView.js

import React from 'react';
import Calendar from './Calendar';
import EventList from './EventList';


const DayView = () => {
  return (
    <div className="container">
      <h2>Ajourd'hui</h2>
      <Calendar currentDate={new Date()} />
      <EventList view="day" />
    </div>
  );
};

export default DayView;
