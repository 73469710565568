import React from 'react';
import { useLocation } from 'react-router-dom';

const Legend = () => {
  const location = useLocation(); // Utiliser useLocation pour vérifier la route actuelle

  // Ajouter dynamiquement la classe "spacer" si on est sur la vue "day" (route "/")
  const containerClass = location.pathname !== "/" ? "container spacer" : "container";

  return (
    <div className={containerClass}>
      <h2>Légende</h2>
      <ul className='legendList'>
        <li className='eventName sirius'>Cours Sirius <em>de 9h à 16h</em></li>
        <li className='eventName interra'>Interra <em>de 9h à 13h</em></li>
        <li className='eventName atelier'>Atelier</li>
        <li className='eventName holiday'>Congés</li>
      </ul>
    </div>
  );
};

export default Legend;