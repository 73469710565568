// src/components/Calendar.js

import React from 'react';
import { format, startOfWeek, addDays, startOfMonth, endOfMonth, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import events from '../data/events';

const Calendar = ({ currentDate, view }) => {
  let startDate, endDate, formattedDate;

  switch (view) {
    case 'week':
      startDate = startOfWeek(currentDate, { weekStartsOn: 1 });
      endDate = addDays(startDate, 4); // Vendredi
      formattedDate = `${format(startDate, 'eeee dd MMMM', { locale: fr })} - ${format(endDate, 'eeee dd MMMM', { locale: fr })}`;
      break;
    case 'month':
      startDate = startOfMonth(currentDate);
      endDate = endOfMonth(currentDate);
      formattedDate = `${format(startDate, 'MMMM yyyy', { locale: fr })}`;
      break;
    case 'all':
      const firstEvent = events.length > 0 ? parseISO(events[0].date) : currentDate;
      const lastEvent = events.length > 0 ? parseISO(events[events.length - 1].date) : currentDate;
      formattedDate = `${format(startOfMonth(firstEvent), 'MMMM yyyy', { locale: fr })} - ${format(endOfMonth(lastEvent), 'MMMM yyyy', { locale: fr })}`;
      break;
    case 'day':
      formattedDate = format(currentDate, 'eeee dd MMMM', { locale: fr });
      break;
    default:
      formattedDate = format(currentDate, 'eeee dd MMMM', { locale: fr });
  }

  return (
    <div>
      <h3>{formattedDate}</h3>
    </div>
  );
};

export default Calendar;