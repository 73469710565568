// src/components/WeekView.js

import React from 'react';
import Calendar from './Calendar';
import EventList from './EventList';

const WeekView = () => {
  return (
    <div className="container">
      <h2>Cette semaine</h2>
      <Calendar view="week" currentDate={new Date()} />
      <EventList view="week" />
    </div>
  );
};

export default WeekView;
