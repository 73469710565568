// src/components/AllDatesView.js

import React from 'react';
import Calendar from './Calendar';
import EventList from './EventList';

const AllDatesView = () => {
  return (
    <div className="container">
      <h2>Toutes les dates</h2>
      <Calendar view="all" currentDate={new Date()} />
      <EventList view="all" />
    </div>
  );
};

export default AllDatesView;
