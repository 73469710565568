import React from 'react';
import { createRoot } from 'react-dom/client'; // Importer createRoot depuis react-dom/client
import './styles.scss'; // Importer le fichier CSS global
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container); // Créer un root container

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
