import React, { useEffect, useState } from 'react';

const ScrollToTodayButton = () => {
  const [isTodayVisible, setIsTodayVisible] = useState(false);

  useEffect(() => {
    const checkTodayElement = () => {
      const todayElement = document.getElementById('today');
      setIsTodayVisible(!!todayElement); // Vérifie si l'élément 'today' existe
    };

    checkTodayElement(); // Vérifie si l'élément "today" est présent lors du montage
  }, []);

  useEffect(() => {
    const scrollToToday = (e) => {
      e.preventDefault();

      const todayElement = document.getElementById('today');
      if (todayElement) {
        const yOffset = -100; // Décalage négatif (modifiez selon vos besoins)
        const elementPosition = todayElement.getBoundingClientRect().top;
        const y = elementPosition + window.scrollY + yOffset;

        window.scrollTo({
          top: y,
          behavior: 'smooth'
        });
      }
    };

    const button = document.getElementById('scrollToToday');
    if (button) {
      console.log("Bouton 'scrollToToday' trouvé.");
      button.addEventListener('click', scrollToToday);
    }

    return () => {
      if (button) {
        button.removeEventListener('click', scrollToToday);
      }
    };
  }, [isTodayVisible]); // Ce useEffect se déclenche lorsque isTodayVisible change

  return (
    <>
      {isTodayVisible && (
        <a href="#today" id="scrollToToday">
          Aller à la semaine en cours
        </a>
      )}
    </>
  );
};

export default ScrollToTodayButton;