const events = [
  // Event de test
  // {
  //   date: "2024-07-29",
  //   event: [
  //     { name: "Début formation 🎉", type: "atelier" },
  //     { name: "HTML: les bases", type: "sirius" },
  //     { name: "Interra: Présentation 45'", type: "interra" },
  //   ]
  // },
  // Event de test

  // WEEK 1
  {
    date: "2024-06-10",
    week: 1,
    event: [
      { name: "Début formation 🎉", type: "sirius" },
      { name: "Onboarding", type: "sirius" },
      { name: "Remise matos", type: "sirius" },
      { name: "Utilisation Discord et VScode", type: "sirius" },
      { name: "Interra: Présentation 45'", type: "interra" }
    ]
  },
  {
    date: "2024-06-11",
    week: 1,
    event: [
      { name: "In The Air - Cohésion d'équipe", type: "atelier" },
      { name: "Explication Github", type: "sirius" }
    ]
  },
  {
    date: "2024-06-12",
    week: 1,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-06-13",
    week: 1,
    event: [
      { name: "Histoire du web", type: "sirius" },
      { name: "Markdown", type: "sirius" }
    ]
  },
  {
    date: "2024-06-14",
    week: 1,
    event: [
      { name: "Télétravail apprenants (mise au vert)", type: "sirius" }
    ]
  },

  // WEEK 2
  {
    date: "2024-06-17",
    week: 2,
    event: [
      { name: "HTML: Théorie", type: "sirius" },
    ]
  },
  {
    date: "2024-06-18",
    week: 2,
    event: [
      { name: "HTML: Théorie", type: "sirius" },
    ]
  },
  {
    date: "2024-06-19",
    week: 2,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-06-20",
    week: 2,
    event: [
      { name: "Journée des réfugiés", type: "holiday" }
    ]
  },
  {
    date: "2024-06-21",
    week: 2,
    event: [
      { name: "Interra: Module 1\r\nÉcoute active, communication non-violente et assertivité", type: "interra" },
      { name: "HTML: Exercices intermédiaires et recette", type: "sirius" }
    ]
  },

  // WEEK 3
  {
    date: "2024-06-24",
    week: 3,
    event: [
      { name: "CSS: Les bases, les sélecteurs et les propriétés", type: "sirius" }
    ]
  },
  {
    date: "2024-06-25",
    week: 3,
    event: [
      { name: "CSS: Les bases, les sélecteurs et les propriétés", type: "sirius" }
    ]
  },
  {
    date: "2024-06-26",
    week: 3,
    event: [
      { name: "Interra: Module 1\r\nCommunication en contexte professionnel, savoir donner un bon feedback", type: "interra" }
    ]
  },
  {
    date: "2024-06-27",
    week: 3,
    event: [
      { name: "CSS: Exercices intermédiaires", type: "sirius" }
    ]
  },
  {
    date: "2024-06-28",
    week: 3,
    event: [
      { name: "CSS: Exercices intermédiaires", type: "sirius" }
    ]
  },

  // WEEK 4
  {
    date: "2024-07-01",
    week: 4,
    event: [
      { name: "CSS: MAquette Discord (+variation)", type: "sirius" }
    ]
  },
  {
    date: "2024-07-02",
    week: 4,
    event: [
      { name: "CSS: MAquette Discord (+variation)", type: "sirius" }
    ]
  },
  {
    date: "2024-07-03",
    week: 4,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-07-04",
    week: 4,
    event: [
      { name: "Interra: Module 2 Interculturalité étape 1 et 2", type: "interra" },
      { name: "CSS: Maquette Discord (+variation)", type: "sirius" }
    ]
  },
  {
    date: "2024-07-05",
    week: 4,
    event: [
      { name: "CSS: Maquette Discord (+variation)", type: "sirius" }
    ]
  },

  // WEEK 5
  {
    date: "2024-07-08",
    week: 5,
    event: [
      { name: "CSS: Maquette Discord (+variation)", type: "sirius" }
    ]
  },
  {
    date: "2024-07-09",
    week: 5,
    event: [
      { name: "CSS: Maquette Discord (+variation)", type: "sirius" }
    ]
  },
  {
    date: "2024-07-10",
    week: 5,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-07-11",
    week: 5,
    event: [
      { name: "Interra: Module 2\r\nInterculturalité étape 3", type: "interra" }
    ]
  },
  {
    date: "2024-07-12",
    week: 5,
    event: [
      { name: "CSS: Maquette Discord (+variation)", type: "sirius" }
    ]
  },
  
  // WEEK 6
  {
    date: "2024-07-15",
    week: 6,
    event: [
      { name: "CSS: Maquette Discord (+variation)", type: "sirius" }
    ]
  },
  {
    date: "2024-07-16",
    week: 6,
    event: [
      { name: "CSS: Maquette Discord (+variation)", type: "sirius" }
    ]
  },
  {
    date: "2024-07-17",
    week: 6,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-07-18",
    week: 6,
    event: [
      { name: "CSS: Maquette Discord (+variation)", type: "sirius" }
    ]
  },
  {
    date: "2024-07-19",
    week: 6,
    event: [
      { name: "CSS: Maquette Discord (+variation)", type: "sirius" }
    ]
  },
  
  // WEEK 7
  {
    date: "2024-07-22",
    week: 7,
    event: [
      { name: "Congé 🏝️", type: "holiday" }
    ]
  },
  {
    date: "2024-07-23",
    week: 7,
    event: [
      { name: "Congé 🏝️", type: "holiday" }
    ]
  },
  {
    date: "2024-07-24",
    week: 7,
    event: [
      { name: "Congé 🏝️", type: "holiday" }
    ]
  },
  {
    date: "2024-07-25",
    week: 7,
    event: [
      { name: "Congé 🏝️", type: "holiday" }
    ]
  },
  {
    date: "2024-07-26",
    week: 7,
    event: [
      { name: "Congé 🏝️", type: "holiday" }
    ]
  },
  
  // WEEK 8
  {
    date: "2024-07-29",
    week: 8,
    event: [
      { name: "Flexbox", type: "sirius" }
    ]
  },
  {
    date: "2024-07-30",
    week: 8,
    event: [
      { name: "Flexbox", type: "sirius" }
    ]
  },
  {
    date: "2024-07-31",
    week: 8,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-08-01",
    week: 8,
    event: [
      { name: "Interra: Module 3\r\nGestion du stress et de l'anxiété", type: "interra" },
      { name: "Flexbox", type: "sirius" },
    ]
  },
  {
    date: "2024-08-02",
    week: 8,
    event: [
      { name: "Jerem pas là = Congé!", type: "holiday" },
    ]
  },

  // WEEK 9
  {
    date: "2024-08-05",
    week: 9,
    event: [
      { name: "Jerem pas là = Congé!", type: "holiday" },
    ]
  },
  {
    date: "2024-08-06",
    week: 9,
    event: [
      { name: "Flexbox", type: "sirius" },
    ]
  },
  {
    date: "2024-08-07",
    week: 9,
    event: [
      { name: "Interra: Module 3\r\nImage de soi et apparence", type: "interra" }
    ]
  },
  {
    date: "2024-08-08",
    week: 9,
    event: [
      { name: "Flexbox", type: "sirius" },
      { name: "Soigne Ta Com': Streaming / Twitch", type: "atelier" }
    ]
  },
  {
    date: "2024-08-09",
    week: 9,
    event: [
      { name: "Flexbox", type: "sirius" },
      { name: "Soigne Ta Com': Streaming / Twitch", type: "atelier" }
    ]
  },

  // WEEK 10
  {
    date: "2024-08-12",
    week: 10,
    event: [
      { name: "Interra: Module 3\r\nRéseautage et relations interpersonnelles", type: "interra" }
    ]
  },
  {
    date: "2024-08-13",
    week: 10,
    event: [
    ]
  },
  {
    date: "2024-08-14",
    week: 10,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-08-15",
    week: 10,
    event: [
      { name: "ASSOMPTION", type: "holiday" }
    ]
  },
  {
    date: "2024-08-16",
    week: 10,
    event: [
    ]
  },
  
  // WEEK 11
  {
    date: "2024-08-19",
    week: 11,
    event: [

    ]
  },
  {
    date: "2024-08-20",
    week: 11,
    event: [
    ]
  },
  {
    date: "2024-08-21",
    week: 11,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-08-22",
    week: 11,
    event: [
    ]
  },
  {
    date: "2024-08-23",
    week: 11,
    event: [
    ]
  },
  
  // WEEK 12
  {
    date: "2024-08-26",
    week: 12,
    event: [
      { name: "Congé 🏝️", type: "holiday" }
    ]
  },
  {
    date: "2024-08-27",
    week: 12,
    event: [
      { name: "Congé 🏝️", type: "holiday" }
    ]
  },
  {
    date: "2024-08-28",
    week: 12,
    event: [
      { name: "Congé 🏝️", type: "holiday" }
    ]
  },
  {
    date: "2024-08-29",
    week: 12,
    event: [
      { name: "Congé 🏝️", type: "holiday" }
    ]
  },
  {
    date: "2024-08-30",
    week: 12,
    event: [
      { name: "Congé 🏝️", type: "holiday" }
    ]
  },


  // WEEK 13
  {
    date: "2024-09-02",
    week: 13,
    event: [
      { name: "Interra: Module 3\r\nPrise de parole en public", type: "interra" }
    ]
  },
  {
    date: "2024-09-03",
    week: 13,
    event: [
      { name: "Soigne Ta Com': InDesign", type: "atelier" }
    ]
  },
  {
    date: "2024-09-04",
    week: 13,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-09-05",
    event: [
      { name: "Correction maquette Feel the Music", type: "sirius" }
    ]
  },
  {
    date: "2024-09-06",
    event: [
      { name: "Correction maquette Feel the Music", type: "sirius" }
    ]
  },
  
  // WEEK 14
  {
    date: "2024-09-09",
    week: 14,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-09-10",
    week: 14,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-09-11",
    week: 14,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-09-12",
    week: 14,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-09-13",
    week: 14,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  
  // WEEK 15
  {
    date: "2024-09-16",
    week: 15,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-09-17",
    week: 15,
    event: [
      { name: "Soigne Ta Com' : Canva.com", type: "atelier" },
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-09-18",
    week: 15,
    event: [
      { name: "Interra: Module 4\r\nQui je suis", type: "interra" }
    ]
  },
  {
    date: "2024-09-19",
    week: 15,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-09-20",
    week: 15,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  
  // WEEK 16
  {
    date: "2024-09-23",
    week: 16,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-09-24",
    week: 16,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-09-25",
    week: 16,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-09-26",
    week: 16,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-09-27",
    week: 16,
    event: [
      { name: "FÊTE DE LA COMMUNAUTÉ FRANÇAISE", type: "holiday" }
    ]
  },

  // WEEK 17
  {
    date: "2024-09-30",
    week: 17,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-10-01",
    week: 17,
    event: [
      { name: "Stepinsight: Introduction à ChatGPT pour coder", type: "atelier" }
    ]
  },
  {
    date: "2024-10-02",
    week: 17,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-10-03",
    week: 17,
    event: [
      { name: "Interra (Nicolas)", type: "interra" },
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-10-04",
    week: 17,
    event: [
      { name: "Introduction JavaScript", type: "sirius" }
    ]
  },
  
  
  //WEEK 18
  {
    date: "2024-10-07",
    week: 18,
    event: [
    ]
  },
  {
    date: "2024-10-08",
    week: 18,
    event: [
      { name: "Interra (Nicolas)", type: "interra" },
      { name: "JavaScript", type: "sirius" }
    ]
  },
  {
    date: "2024-10-09",
    week: 18,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-10-10",
    week: 18,
    event: [
      { name: "Hackathon", type: "atelier" }
    ]
  },
  {
    date: "2024-10-11",
    week: 18,
    event: [
      { name: "Hackathon", type: "atelier" }
    ]
  },
  
  // WEEK 19  
  {
    date: "2024-10-14",
    week: 19,
    event: [
    ]
  },
  {
    date: "2024-10-15",
    week: 19,
    event: [
    ]
  },
  {
    date: "2024-10-16",
    week: 19,
    event: [
      { name: "Interra (Emilie)", type: "interra" }
    ]
  },
  {
    date: "2024-10-17",
    week: 19,
    event: [
    ]
  },
  {
    date: "2024-10-18",
    week: 19,
    event: [
      { name: "Congé toute la journée!!", type: "holiday" }
    ]
  },

  // WEEK 20
  {
    date: "2024-10-21",
    week: 20,
    event: [
    ]
  },
  {
    date: "2024-10-22",
    week: 20,
    event: [
    ]
  },
  {
    date: "2024-10-23",
    week: 20,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-10-24",
    week: 20,
    event: [
    ]
  },
  {
    date: "2024-10-25",
    week: 20,
    event: [
    ]
  },


  // WEEK 21
  {
    date: "2024-10-28",
    week: 21,
    event: [
      { name: "Intro UI/UX (de Shervin Sardari)", type: "atelier" }
    ]
  },
  {
    date: "2024-10-29",
    week: 21,
    event: [
      { name: "Suite UI/UX + UX Lab (de Shervin Sardari)", type: "atelier" }
    ]
  },
  {
    date: "2024-10-30",
    week: 21,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-10-31",
    week: 21,
    event: [
    ]
  },
  {
    date: "2024-11-01",
    week: 21,
    event: [
      { name: "TOUSSAINT", type: "holiday" }
    ]
  },
  
  
  // WEEK 22
  {
    date: "2024-11-04",
    week: 22,
    event: [
    ]
  },
  {
    date: "2024-11-05",
    week: 22,
    event: [
    ]
  },
  {
    date: "2024-11-06",
    week: 22,
    event: [
      { name: "Interra: Création CV / Lettre de motivation", type: "interra" }
    ]
  },
  {
    date: "2024-11-07",
    week: 22,
    event: [
      { name: "StepInsight - Allez plus loin avec une IA", type: "atelier" }
    ]
  },
  {
    date: "2024-11-08",
    week: 22,
    event: [
    ]
  },
  
  // WEEK 23
  {
    date: "2024-11-11",
    week: 23,
    event: [
      { name: "ARMISTICE", type: "holiday" }
    ]
  },
  {
    date: "2024-11-12",
    week: 23,
    event: [
    ]
  },
  {
    date: "2024-11-13",
    week: 23,
    event: [
      { name: "Congé 🎉", type: "holiday" }
    ]
  },
  {
    date: "2024-11-14",
    week: 23,
    event: [
    ]
  },
  {
    date: "2024-11-15",
    week: 23,
    event: [
      { name: "Fin Formation 🎉", type: "sirius" }
    ]
  },
  
  // WEEK 24
  {
    date: "2024-11-18",
    week: 24,
    event: [
    ]
  },
  {
    date: "2024-11-19",
    week: 24,
    event: [
    ]
  },
  {
    date: "2024-11-20",
    week: 24,
    event: [
    ]
  },
  {
    date: "2024-11-21",
    week: 24,
    event: [
    ]
  },
  {
    date: "2024-11-22",
    week: 24,
    event: [
      { name: "Colloque Sirius 2024 🎉 🍾 🥂", type: "sirius" }
    ]
  }
  
];

export default events;