// src/components/MonthView.js

import React from 'react';
import Calendar from './Calendar';
import EventList from './EventList';

const MonthView = () => {
  return (
    <div className="container">
      <h2>Ce mois</h2>
      <Calendar view="month" currentDate={new Date()} />
      <EventList view="month" />
    </div>
  );
};

export default MonthView;
