import React from 'react';
import { useLocation } from 'react-router-dom';


// Utilisation de require.context pour importer toutes les images du dossier
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../assets/images', false, /\.(png|jpe?g|svg)$/));

const RandomImage = () => {
  const location = useLocation(); // Utiliser useLocation pour vérifier la route actuelle
    // Ajouter dynamiquement la classe "spacer" si on est sur la vue "day" (route "/")
  const containerClass = location.pathname === "/" ? "randomImage spacer" : "randomImage";

  const randomIndex = Math.floor(Math.random() * images.length);
  const selectedImage = images[randomIndex];

  return (
    <div className={containerClass}>
      <h2>Meme aléatoire</h2>
      <a href={selectedImage} target="_blank" rel='noreferrer'>
       <img src={selectedImage} alt="Random Meme" />
      </a>
    </div>
  );
};

export default RandomImage;